import { modulosAcceso } from '../../menu';

export const getProductos = [
  "Regular",
  "Premium",
  "Diesel",
];

export const getColors = [
  "green",
  "red",
  "default",
];

export const getColorsProductos = {
  [getProductos[0]]: getColors[0],
  [getProductos[1]]: getColors[1],
  [getProductos[2]]: getColors[2],
};

export const getMercado = [
  "Total México",
  "Región",
  "Mis mercados",
  "Distribuidor",
  "Gasolineras",
  "Dealer",
];

export const getReceptorTipo = [
  "Total Valero",
  "Usuarios Corporativo",
  "Distribuidor",
  "Dealer",
  "Usuario",
];

export const getAudienciaTipo = [
  "Total Valero",
  "Usuarios Corporativo",
  "Distribuidor",
  "Dealer",
  "Usuario",
];

export const getFrecuencia = [
  "Diario",
  "Semanal",
  "Mensual",
];

let dias = [];
let cont = 1;
while(cont <= 31) {
  dias = [ ...dias, cont ];
  cont += 1;
}

export const getDias = [ ...dias ];

export const getDiasSemana = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};

export const selectWithError = [{
  value: '',
  disabled: true,
  label: 'Ocurrio un problema al obtener la información'
}];

export const selectModulosAcceso = modulosAcceso.filter(({noSelect}) => !noSelect).map(({ title:label, key:value }) => ({ label, value }));

export const validateMessages = {
  required: '¡El campo ${label} es requerido!',
  types: {
    email: '¡${label} no tiene el formato de correo válido!',
    number: '¡${label} no es un número válido!',
  },
  number: {
    range: '${label} debe estar en un rango entre ${min} y ${max}',
  },
};

export const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export function toUpperCaseFirsLetter(word) {
  if(!word || word.trim() === '') return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const rules = { required: true, type: 'array' };
const camposMercado = [
  { name: 'regiones' },
  { name: 'misMercados', label: 'mercados' },
  { name: 'distribuidores' },
  { name: 'gasolineras' },
];
let infoSelectMercados = {};
let countSelect = 0;
while(countSelect < camposMercado.length) {
  const { label, name } = camposMercado[countSelect];
  const word = toUpperCaseFirsLetter(label || name);
  infoSelectMercados = {
    ...infoSelectMercados,
    [getMercado[countSelect+1]]: {
      name,
      label: `${word}:`,
      rules: [
        {
          ...rules,
          message: `Por favor seleccione ${word}`,
        },
      ],
    },
  };
  countSelect += 1;
}

export const getSelectConfigMercados = { ...infoSelectMercados };

const camposReceptores = [
  { name: 'Distribuidores' },
  { name: 'Dealers' },
  { name: 'Usuarios' },
];

let infoSelectReceptores = {};
let countSelectReceptores = 0;
while(countSelectReceptores < camposReceptores.length) {
  const { label, name } = camposReceptores[countSelectReceptores];
  const word = toUpperCaseFirsLetter(label || name);
  infoSelectReceptores = {
    ...infoSelectReceptores,
    [getReceptorTipo[countSelectReceptores+2]]: {
      name,
      label: `${word}:`,
      rules: [
        {
          ...rules,
          message: `Por favor seleccione ${word}`,
        },
      ],
    },
  };
  countSelectReceptores += 1;
}

export const getSelectConfigReceptores = { ...infoSelectReceptores };
export const getSelectConfigAudiencias = { ...infoSelectReceptores };


export const getTipoPregunta = [
  "Unica",
  "Multiple",
];

export const getSelectTipoPregunta = [
  { value: getTipoPregunta[0], label: "Una respuesta" },
  { value: getTipoPregunta[1], label: "Casillas" },
];

export const getRespuestasSiNo = [
  'Sí',
  'No',
  'Sin respuesta',
];

export const getZonaInfluencia = [
  { value: "microMercadoValero", label: "Micro-Mercado Valero" },
  { value: "microMercadoPers", label: "Micro-Mercado Pers." },
  { value: "1km", label: "1 Km" },
  { value: "2km", label: "2 Km" },
  { value: "3km", label: "3 Km" },
  { value: "5km", label: "5 Km" },
];

export const getAgrupadorMercados = [
  { value: "Distribuidores", label: "Distribuidores" },
  { value: "Gasolineras", label: "Suma de estaciones" },
];

export const getDiasPeriodoDeAnalisis = [
  { value: 1, label: "1 día" },
  { value: 7, label: "1 semana" },
  { value: 14, label: "2 semana" },
  { value: 21, label: "3 semana" },
  { value: 28, label: "4 semana" },
];

export const getDiasDashboard = [
  { value: 0, label: "Hoy" },
  { value: 7, label: "Hace 7 días" },
  { value: 14, label: "Hace 14 días" },
  { value: 30, label: "Hace 30 días" }
];

export const getTypePriceGap = [
  { value: 'recurrente', label: 'Recurrente' },
  { value: 'oneshot', label: 'Oneshot' },
];

export const getStatusPush = {
  sinPush: { name: 'Sin Push', color: 'error' },
  enviado: { name: 'Envíado', color: 'success' },
  pendiente: { name: 'Pendiente', color: 'orange' }
};

export const getTipoNotificacionPush = [
  { value: "enviar", label: "Enviar en este momento" },
  { value: "programar", label: "Programar" },
];
