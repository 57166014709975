import { Crud } from '../index';
import { selectWithError } from './Hardcode';

// About me
const userMe = new Crud('auth/users/me');

export function getUserMe() { return userMe.Get(); }

// About me
const authUsers = new Crud('auth/users');

export function postNewUser(data) { return authUsers.Post(data); }

export function getUsuariosAll() { return authUsers.Get(); }

export async function selectUsuarios() {
  const { data } = await getUsuariosAll();
  if(!data) return selectWithError;
  return data.map(({ id:value, name: label }) => ({ value, label }));
}

export function getUsuario(id) { return authUsers.Get(id); }

export function addUsuarios(data) { return authUsers.Post(data); }

export function updateUsuarios(data) { return authUsers.Put(data); }

export function deleteUsuarios(id) { return authUsers.Delete(id); }

// Reset password
const password = new Crud('auth/users/reset-password');

export function getNewPassword(id, email) { return password.Post(!email ? { id } : { email }); }

// Reset password without token
const passwordWithEmail = new Crud('reset-password', false);

export function getNewPasswordWithEmail(email) { return passwordWithEmail.Post(email); }

const disableUser = new Crud('auth/users/disable');

export function desabilitarUsuario(data){return disableUser.Post(data);}

const enableUser = new Crud('auth/users/enable');

export function habilitarUsuario(data){return enableUser.Post(data);}

// Change password
const changePassword = new Crud('auth/users/set-password');

export function setNewPassword(data) { return changePassword.Post(data); }
