import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'



const firebaseConfig = {
  apiKey: "AIzaSyDzEvmtmASOkt5GTBWthFAj1dvKPPzPAWI",
  authDomain: "gasgas-valero.firebaseapp.com",
  databaseURL: "https://gasgas-valero.firebaseio.com",
  projectId: "gasgas-valero",
  storageBucket: "gasgas-valero.appspot.com",
  messagingSenderId: "959132901391",
  appId: "1:959132901391:web:c3c26ab03596f670e4037a",
  measurementId: "G-2R559KDHNV"
};

firebase.initializeApp(firebaseConfig)
export const firebaseAuth = firebase.auth()
// export const firebaseDatabase = firebase.database().refFromURL("https://gasgas-valero.firebaseio.com")
// console.log(firebaseDatabase)

export async function getToken() {
  try {
    const idToken = await firebase.auth().currentUser.getIdToken(true);
    console.log(idToken);
    return idToken;
  } catch({ message:description, code:message }) {
    notification.error({ message, description });
    return false;
  }
}

export async function login(email, password) {
  return firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function register(email, password, name) {
  return firebaseAuth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      console.log("🚀 ~ file: index.js ~ line 39 ~ register ~ response", response, name)
      // if (response.user) {
      //   const { uid } = response.user
      //   firebaseDatabase
      //     .ref('users')
      //     .child(uid)
      //     .set({
      //       role: 'admin',
      //       name,
      //     })
      // }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        // console.log(user)
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {

          if (user) {
            // const userFields = await firebaseDatabase
            //   .ref('users')
            //   .child(user.uid)
            //   .once('value')
            //   .then(snapshot => {
            //     return snapshot.val()
            //   })
            // const mergedUser = Object.assign(user, {
            //   id: user.uid,
            //   name: userFields.name,
            //   role: userFields.role,
            //   avatar: user.photoUrl,
            // })
            const userFields={name:'usuario',role:'admin'}
            const mergedUser = Object.assign(user, {
              id: user.uid,
              name: userFields.name,
              role: userFields.role,
              avatar: user.photoUrl,
            })
            return mergedUser
          }
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth)
}

export async function logout() {
  return firebaseAuth.signOut().then(() => true)
}
