import React from 'react'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar,  Typography } from 'antd'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  console.log("🚀 ~ file: index.js ~ line 11 ~ user", user)
  const { Text} = Typography;

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Divider />
      <Menu.Item>
        <Link to={`/usuarios/Configuracion/${user.idUser}`}>
          <i className="fe fe-user mr-2" />
          Configuración
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          Cerrar Sesión
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <div style={{display: 'flex', flexDirection:'row'}}>
          <Avatar
            style={{marginTop:10,}}
            className={styles.avatar}
            shape="square"
            size="large"
            src={user.avatar !== '' ? user.avatar : undefined}
            icon={user.avatar === '' ? <UserOutlined /> : undefined}
          />
          <div style={{
            border: '1px solid #7D7D7D',
            marginLeft:20,
            marginRight:20,
            marginTop:10,
            height: 43,
          }}
          />
          <Text strong>{user.name || 'Anonymous'}</Text>
        </div>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
