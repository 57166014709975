/* eslint-disable react/react-in-jsx-scope */
import {
  ApartmentOutlined,
  BarChartOutlined,
  CarOutlined,
  CompassOutlined,
  DashboardOutlined,
  DollarOutlined,
  FilePdfOutlined,
  GlobalOutlined,
  LineChartOutlined,
  MailOutlined,
  PlusOutlined,
  UserOutlined,
  BellOutlined,
  ContainerOutlined,
  VideoCameraOutlined
} from '@ant-design/icons'
import React from 'react'

export const modulosAcceso = [
  {
    title: 'Dashboard',
    key: 'Dashboard',
    icon: <DashboardOutlined />,
    url: '/dashboard',
  },
  {
    title: 'Mapa de precios',
    key: 'MapaDePrecios',
    icon: <CompassOutlined />,
    url: '/mapa-de-precios',
  },
  {
    title: 'Analitico de precios',
    key: 'analiticoDePrecios',
    icon: <BarChartOutlined />,
    url: '/analitico-de-precios',
  },
  {
    title: 'Tendencia en precio',
    key: 'TendenciaEnPrecio',
    icon: <LineChartOutlined />,
    url: '/tendencia-en-precio',
  },
  {
    title: 'Reporte Price Gap',
    key: 'ReportePriceGap',
    icon: <DollarOutlined />,
    url: '/reporte-Price-Gap',
  },
  {
    title: 'Mis reportes y alertas',
    key: 'MisReportesYAlertas',
    icon: <FilePdfOutlined />,
    url: '/mis-reportes-y-alertas',
  },
  {
    title: 'Mis mercados',
    key: 'MisMercados',
    icon: <ApartmentOutlined />,
    url: '/mis-mercados',
  },
  {
    title: 'Regiones geográficas',
    key: 'RegionesGeográficas',
    icon: <GlobalOutlined />,
    url: '/Regiones-geográficas',
  },
  {
    title: 'Características',
    key: 'Características',
    icon: <PlusOutlined />,
    url: '/caracteristicas',
  },
  {
    title: 'Dealers',
    key: 'Dealers',
    icon: <ContainerOutlined />,
    url: '/dealers/dealers',
  },
  {
    title: 'Comunicados',
    key: 'Comunicados',
    icon: <BellOutlined />,
    url: '/comunicados/normal',
  },
  {
    title: 'Distribuidores',
    key: 'Distribuidores',
    icon: <CarOutlined />,
    url: '/distribuidores',
  },
  {
    title: 'Tutorials',
    key: 'Tutorials',
    icon: <VideoCameraOutlined />,
    url: '/tutorials/tutorials',
  },
  {
    title: 'Usuarios',
    key: 'Usuarios',
    icon: <UserOutlined />,
    url: '/usuarios',
  },
  {
    title: 'Ayuda y contacto',
    key: 'AyudaYContacto',
    icon: <MailOutlined />,
    url: '/Ayuda-y-contacto',
    noSelect: true,
  },
];

export default async function getMenuData() {
  return [ ...modulosAcceso ];
}
