import React from 'react'
import { connect } from 'react-redux'
import { Col, Layout, Row } from 'antd'
import {  withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  authPagesColor,
}) => {
  return (
    <Layout>
      <Layout.Content>
        <div
          className={classNames(`${style.container}`)}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/7.jpg)' : '',
          }}
        >
          <Row style={{width:'100%'}}>
            <Col lg={14}>
              <img
                src="resources/images/login.png"
                alt="Valero"
                style={{ width: '100%', maxHeight: '100vh'}}
                // onError={(e) => {
                //   e.target.src = `resources/images/logosGasolineras/otro.png` // some replacement image
                // }}
              />
            </Col>
            <Col lg={10}>
              <div className={style.containerInner}>
                {children}
                <div className="text-center" style={{color:'#969696'}}>
                  - Powered by GasGas App -
                </div>
              </div>
            </Col>
          </Row>
          
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
