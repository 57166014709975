import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import { getToken, logout } from '../firebase'

const apiClient = axios.create({
  baseURL: '/api',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
    notification.warning({
      message: data,
    })
  }
})

export class Crud {

  constructor(url = '', hasToken = true) {
    this.url = url;
    this.hasToken = hasToken;
  }

  getDomain (url = '') { return `${global.domain}${this.url}/${url}`; }

  async sendRequest({ url, info, method, showError = true, header = {} }) {
    try {
      let token = {};
      if(this.hasToken) {
        const tkn = await getToken();
        token = { "Authorization": `Bearer ${tkn}` };
      }
      const { data } = await axios({
        method,
        data: info,
        url: this.getDomain(url),
        timeout: 300000,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          ...header,
          ...token,
        },
      });
      if(data.error && showError) throw new Error(data.errorMessage);
      return data;
    } catch({message, response}) {
      if(response?.status === 401) {
        logout();
        window.location.reload();
      }
      notification.error({ message }); return false;
    }
  }

  async Get (url, showError) { return this.sendRequest({ url, showError, method: 'get' }); }

  async Post (info, showError, header) { return this.sendRequest({ info, showError, method: 'post', header }); }

  async PostUrl (url, showError, header) { return this.sendRequest({ url, showError, method: 'post', header }); }

  async Put (info, showError) { return this.sendRequest({ info, showError, method: 'put' }); }

  async Delete (url, showError) { return this.sendRequest({ url, showError, method: 'delete' }); }

}

export default apiClient;
